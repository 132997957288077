<template>
    <div id="newsDetailPage" v-html="text">

    </div>
</template>

<script>
export default {
  name: 'NewsDetail',
  components: {},
  data () {
    return {
      text: ''
    };
  },
  created () {
  },
  mounted () {
    const { meta, query: { itemObj } } = this.$route;
    this.text = itemObj.twoType === 1 ? itemObj.title : itemObj.text;
    meta.title = itemObj.twoType === 1 ? 'サブスク解約のお知らせ' : itemObj.title;
  },
  watch: {},
  methods: {}
};
</script>

<style scoped lang="less">

</style>